
import { Component, Prop, Vue } from "vue-property-decorator";
import System from "@/models/System";
import Notification from "@/models/Notification";
import * as NotificationServices from "@/api/helpers/Notification";
import Notify from "@/utils/notifications";

@Component
export default class NotificationDrawer extends Vue {
  @Prop() notification!: Notification;
  @Prop() create!: boolean;
  @Prop() show!: boolean;
  @Prop() loading!: boolean;
  @Prop() system!: System;

  rules = {
    question: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    answer: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };

  createNotification() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        this.notification.systemId = this.system.id;
        NotificationServices.create(this.notification)
          .then(() => {
            this.$emit("newNotification");
            Notify.successful("Notificación creada exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateNotification() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.notification && this.notification.id)
          NotificationServices.update(this.notification.id, this.notification)
            .then(() => {
              Notify.successful("Notificación actualizada exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.$emit("newNotification");
              this.closeDrawer();
            });
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  get title() {
    return this.create ? "Crear notificación" : "Actualizar notificación";
  }

  get showDrawer() {
    return this.show;
  }
}
