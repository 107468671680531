import { Gondor } from "../Gondor";
import { NotificationItem } from "@/models/Notification";

const ACTIONS = {
  NOTIFICATION: "/notification/",
  NOTIFICATION_ITEM: "/notification/{id}/",
  NOTIFICATION_ORDER: "/notification/order/",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.NOTIFICATION, { params });
}

export async function findOne(id: number | string) {
  id = id.toString();
  const response = await Gondor.API.get(
    ACTIONS.NOTIFICATION_ITEM.replace("{id}", id)
  );
  return response.data.data as NotificationItem;
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.NOTIFICATION, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.NOTIFICATION_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.NOTIFICATION_ITEM.replace("{id}", id));
}

export function order(body: object) {
  return Gondor.API.patch(ACTIONS.NOTIFICATION_ORDER, body);
}
