export default class Notification {
  id?: number;
  title!: string;
  content!: string;
  order?: number;
  active = true;
  createdAt?: Date;
  systemId!: number;
  updatedAt?: Date;
}

export interface NotificationItem extends Omit<Notification, "order"> {
  order: number | null;
}
